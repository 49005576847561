.hero-container {
  position: relative;
  height: 92vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../../assets/wallpaperflare.com_wallpaper (12) (1).jpg') center center/cover no-repeat;
  overflow: hidden;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 0 20px;
}

.hero-title {
  background: linear-gradient(
    to right,
    #999 5%,
    #fff 10%,
    #ccc 30%,
    #ddd 50%,
    #ccc 70%,
    #fff 90%,
    #999 95%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 4s ease-in infinite forwards;
  font-size: 65px;
  font-weight: 400;
  margin-top: 99px;
  margin-bottom: 20px;
}

.hero-subtitle {
  background: linear-gradient(
    to right,
    #999 5%,
    #fff 10%,
    #ccc 30%,
    #ddd 50%,
    #ccc 70%,
    #fff 90%,
    #999 95%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 4s ease-in infinite forwards;
  font-size: 65px;
  font-weight: 100;
  margin-bottom: 30px;
}

.home-carousel {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.hero-btns {
  margin-top: 32px;
}

.hero-button {
  padding: 12px 24px;
  font-size: 18px;
  background: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hero-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

/* Height-based Media Queries */
@media screen and (max-height: 980px) {
  .home-carousel { top: 40%; }
}

@media screen and (max-height: 920px) {
  .home-carousel { top: 43%; }
}

@media screen and (max-height: 860px) {
  .home-carousel { top: 45%; }
}

@media screen and (max-height: 800px) {
  .home-carousel { top: 48%; }
}

@media screen and (max-height: 760px) {
  .home-carousel { top: 50%; }
}

@media screen and (max-height: 700px) {
  .home-carousel { top: 52%; }
}

/* Width-based Media Queries */
@media screen and (max-width: 960px) {
  .hero-title {
    font-size: 70px;
    margin-top: -150px;
  }
  
  .hero-subtitle {
    font-size: 50px;
  }
}

@media screen and (max-width: 768px) {
  .hero-title {
    font-size: 50px;
    margin-top: -120px;
  }

  .hero-subtitle {
    font-size: 30px;
  }

  .hero-button {
    width: 100%;
    max-width: 300px;
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .hero-title {
    font-size: 40px;
  }

  .hero-subtitle {
    font-size: 20px;
  }

  .hero-button {
    font-size: 15px;
    padding: 10px 20px;
  }
}

@keyframes textShine {
  to {
    background-position: 200%;
  }
}