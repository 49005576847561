
@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.app-container{
  /* max-height: calc(100vh - 74px); */
    overflow: auto;
    position: relative;
    height: 93vh;

}
@media (max-width: 1900px) {
  .app-container {
    height: 96vh;
  }
}

.container{
    display: flex;
    height: 100%;
}