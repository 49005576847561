.navbar-wrapper {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    background-color: #02050e;
    padding: 0 20px;
  }
  
  .navbar-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 268px;
    height: 100%;
  }
  
  .nav-image {
    width: 200px;
    height: auto;
  }
  
  .nav-logo {
    width: 100%;
    height: auto;
  }
  
  .hamburger-icon {
    display: none;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 440px;
  }
  
  .items {
    background: linear-gradient(
      45deg,
      #999 5%,
      #fff 10%,
      #ccc 30%,
      #ddd 50%,
      #ccc 70%,
      #fff 80%,
      #999 95%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .items:hover {
    transform: translateY(-2px);
  }
  
  .nav-item-quote {
    padding: 10px 20px;
    border-radius: 12px;
    border: 1px solid white;
    background: linear-gradient(
      45deg,
      #999 5%,
      #fff 10%,
      #ccc 30%,
      #ddd 50%,
      #ccc 70%,
      #fff 80%,
      #999 95%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .nav-item-quote:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
  }
  
  /* Tablet Styles */
  @media screen and (max-width: 1024px) {
    .navbar-container {
      height: 125px;
      padding: 0 15px;
    }
  
    .nav-item {
      width: auto;
      gap: 15px;
    }
  
    .items {
      font-size: 14px;
    }
  
    .nav-item-quote {
      padding: 8px 15px;
      font-size: 14px;
    }
  }
  
  /* Mobile Styles */
  @media screen and (max-width: 768px) {
    .navbar-container {
      height: 80px;
      padding: 0 10px;
      position: relative;
    }
  
    .navbar-div {
      width: 100%;
      justify-content: space-between;
      padding: 0 10px;
    }
  
    .nav-image {
      width: 150px;
    }
  
    .hamburger-icon {
      display: block;
    }
  
    .nav-item {
      display: none;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      background-color: #02050e;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      gap: 15px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  
    .nav-item.open {
      display: flex;
    }
  
    .items {
      padding: 10px 0;
      width: 100%;
      text-align: center;
    }
  
    .nav-item-quote {
      display: none;
    }
  
    .nav-item.open .nav-item-quote {
      display: block;
      margin-top: 15px;
      width: 80%;
      text-align: center;
    }
  }
  
  /* Small Mobile Styles */
  @media screen and (max-width: 480px) {
    .nav-image {
      width: 120px;
    }
  
    .items {
      font-size: 14px;
    }
  }