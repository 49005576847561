.services-wrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
  }
  
  /* Hero Section */
  .hero-section {
    position: relative;
    width: 100%;
    height: 400px;
    margin-top: 80px;
    background: url("../../../src/assets/programming-background-with-person-working-with-codes-computer (1) (2).jpg") center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .hero-title {
    position: relative;
    z-index: 2;
    color: white;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    max-width: 90%;
    margin: 0 auto;
  }
  
  /* Services Grid Section */
  .services-grid-section {
    padding: 60px 5%;
    background: #fff;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .service-card {
    border: 1px solid #eee;
    border-radius: 12px;
    padding: 25px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    background: #fff;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  }
  
  .service-content {
    text-align: center;
  }
  
  .service-image {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
  }
  
  .service-card:hover .service-image {
    transform: scale(1.1);
  }
  
  .service-title {
    font-size: 1.4rem;
    margin-bottom: 15px;
    color: #333;
    font-weight: 600;
  }
  
  .service-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  /* Why Us Section */
  .why-us-section {
    background: #f5f5f5;
    padding: 80px 5%;
  }
  
  .why-us-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  .section-description {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 60px;
    line-height: 1.8;
    color: #666;
    font-size: 1.1rem;
  }
  
  .steps-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  .step-item {
    display: flex;
    align-items: flex-start;
    gap: 25px;
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    transition: transform 0.3s ease;
  }
  
  .step-item:hover {
    transform: translateX(10px);
  }
  
  .step-number {
    width: 60px;
    height: 60px;
    background: #000;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
    flex-shrink: 0;
  }
  
  .step-content {
    flex: 1;
  }
  
  .step-title {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #333;
    font-weight: 600;
  }
  
  .step-description {
    color: #666;
    line-height: 1.6;
    font-size: 1rem;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 1200px) {
    .hero-title {
      font-size: 2.5rem;
    }
    
    .services-grid-section {
      padding: 40px 4%;
    }
    
    .service-card {
      padding: 20px;
    }
  }
  
  @media screen and (max-width: 900px) {
    .hero-title {
      font-size: 2rem;
    }
    
    .services-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    
    .section-title {
      font-size: 2rem;
    }
    
    .section-description {
      font-size: 1rem;
      padding: 0 20px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .hero-section {
      height: 300px;
    }
    
    .hero-title {
      font-size: 1.75rem;
    }
    
    .services-grid {
      grid-template-columns: 1fr;
    }
    
    .services-grid-section {
      padding: 30px 4%;
    }
    
    .why-us-section {
      padding: 40px 4%;
    }
    
    .step-item {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 15px;
    }
    
    .step-item:hover {
      transform: none;
    }
    
    .step-number {
      width: 50px;
      height: 50px;
      font-size: 1.25rem;
      margin-bottom: 15px;
    }
    
    .section-title {
      font-size: 1.75rem;
    }
    
    .step-title {
      font-size: 1.2rem;
    }
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .service-card {
    animation: fadeInUp 0.6s ease-out forwards;
  }
  
  .step-item {
    animation: fadeInUp 0.6s ease-out forwards;
  }