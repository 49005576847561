.contact-container {
    position: relative;
  }
  
  .contact-image img {
    width: 100%;
    height: 108vh;
  }
  
  .contact-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5); /* Adding transparency */
    color: #030303;
  }
  
  .carousel-button-div {
    display: flex;
    position: relative;
    top: 333px;
    z-index: 18;
  }
  
  .form-div {
    position: relative;
    width: 60%;
    left: 22%;
    background: #f8f9fae6;
    padding: 25px 50px;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 2%);
    border-radius: 6px;
    margin-top: 109px;
  }
  
  .form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .form-text-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  
  .Labelcontent {
    display: flex;
    align-items: center;
    margin: 5px 0;
    text-align: start;
  }
  
  .Labelcontent .required-star {
    margin-left: 4px;
  }
  
  .DescriptionContainer {
    padding: 10px;
    font-size: 20px;
    font-family: math;
    font-weight: 600;
  }
  
  .descriptionDiv {
    border-radius: 8px;
    margin-top: 20px;
    padding: 20px 20px 45px;
    background: #a1c2f7a1;
  }
  
  .descriptionDiv textarea {
    border-radius: 8px;
    padding: 3px;
    border: 1px solid #a1c2f7a1;
  }
  
  .inputContent {
    width: 40%;
    border-radius: 8px;
    padding: 3px;
    border: 1px solid #a1c2f7a1;
  }
  
  .submitButton {
    display: grid;
    place-items: center;
    width: 100%;
    margin-top: 20px;
  }
  
  .btn-submit {
    background-color: #1d272b;
    color: #f8f9fa;
    border-radius: 6px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .contact-address {
    position: relative;
    width: 43%;
    left: 30%;
    margin-top: 30px;
    background: #f8f9fae6;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 2%);
    border-radius: 6px;
    float: left;
  }
  
  .office-address {
    font: 400 16px/28px 'Open Sans', sans-serif;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    display: flex
;
    align-items: center;
  }
  
  .office-head {
    font: normal 600 22px/26px 'Montserrat', sans-serif;
  }
  
  /* Media Queries */
  @media (max-width: 1200px) {
    .form-div {
      width: 70%;
      left: 15%;
    }
  
    .contact-address {
      width: 50%;
      left: 25%;
    }
  }
  
  @media (max-width: 992px) {
    .form-div {
      width: 80%;
      left: 10%;
    }
  
    .contact-address {
      width: 60%;
      left: 20%;
    }
  }
  
  @media (max-width: 768px) {
    .form-div {
      width: 90%;
      left: 5%;
    }
  
    .contact-address {
      width: 70%;
      left: 15%;
    }
  }
  
  @media (max-width: 576px) {
    .form-div {
      width: 100%;
      left: 0;
      padding: 20px;
    }
  
    .contact-address {
      width: 90%;
      left: 5%;
      padding: 20px;
    }
  
    .inputContent {
      width: 100%;
    }
  }
  