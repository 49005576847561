.footer-container {
    background-color: #000000;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.footer-wrap {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    height: 74px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    width: 100%;
}

.social-logo {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    transition: color 0.3s ease;
}

.social-logo:hover {
    color: #808080;
}

.website-rights {
    color: #fff;
    text-align: center;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.social-icon-link {
    color: #fff;
    font-size: 24px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon-link:hover {
    color: #808080;
    transform: translateY(-2px);
}

/* Tablet Styles */
@media screen and (max-width: 1024px) {
    .footer-wrap {
        padding: 15px 10px;
        height: 70px;
    }

    .social-logo {
        font-size: 22px;
    }

    .website-rights {
        font-size: 12px;
    }

    .social-icons {
        width: 180px;
    }

    .social-icon-link {
        font-size: 20px;
    }
}

/* Mobile Styles */
@media screen and (max-width: 600px) {
    .footer-wrap {
        padding: 15px 10px;
        height: 74px;
    }

    .social-logo {
        font-size: 18px;
    }

    .website-rights {
        font-size: 8px;
        padding-left: 17px;
        max-width: 120px;
    }

    .social-icons {
        width: 130px;
    }

    .social-icon-link {
        font-size: 18px;
    }
}

/* Extra Small Mobile Styles */
@media screen and (max-width: 375px) {
    .social-media-wrap {
        flex-direction: column;
        gap: 10px;
        text-align: center;
    }

    .footer-wrap {
        height: auto;
        padding: 15px 10px;
    }

    .website-rights {
        padding-left: 0;
        margin: 8px 0;
        max-width: none;
    }

    .social-icons {
        width: 100%;
        justify-content: center;
        gap: 15px;
    }
}