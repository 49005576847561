.carousel-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
}

.carousel-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.3);
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
  transition: all 0.3s ease;
  color: white;
}

.carousel-button:hover {
  background: rgba(0, 0, 0, 0.5);
  transform: translateY(-50%) scale(1.1);
}

.carousel-button:active {
  transform: translateY(-50%) scale(0.95);
}

.carousel-button-left {
  left: 20px;
}

.carousel-button-right {
  right: 20px;
}

/* Tablet styles */
@media screen and (max-width: 1024px) {
  .carousel-button {
    width: 50px;
    height: 50px;
  }
  
  .carousel-button-left {
    left: 15px;
  }
  
  .carousel-button-right {
    right: 15px;
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .carousel-button {
    width: 40px;
    height: 40px;
  }
  
  .carousel-button-left {
    left: 10px;
  }
  
  .carousel-button-right {
    right: 10px;
  }
}

/* Animation for button appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50%) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}

/* Optional hover effect for desktop */
@media (hover: hover) {
  .carousel-button {
    opacity: 0.6;
  }
  
  .carousel-button:hover {
    opacity: 1;
  }
}

/* Touch device optimization */
@media (hover: none) {
  .carousel-button {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
  }
}