.about-wrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: #fff;
  }
  
  /* Hero Section */
  .about-hero {
    position: relative;
    width: 100%;
    height: 400px;
    margin-top: 80px;
    background: url("../../../src/assets/programming-background-with-person-working-with-codes-computer (3).jpg") center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .hero-title {
    position: relative;
    z-index: 2;
    color: #fff;
    font-size: 40px;
    text-align: center;
    line-height: 1.4;
    padding: 0 20px;
    margin: 0;
    font-weight: 600;
  }
  
  /* Main Content */
  .about-content {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .about-title {
    text-align: center;
    font-size: 34px;
    font-weight: 600;
    color: #333;
    margin-bottom: 40px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .content-section {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .content-text {
    color: #666;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 24px;
    padding: 0 40px;
  }
  
  .section-heading {
    color: #333;
    font-size: 24px;
    font-weight: 600;
    margin: 32px 0 16px;
    padding: 0 40px;
  }
  
  .content-footer {
    margin-top: 60px;
    text-align: center;
    padding: 0 40px;
  }
  
  .footer-text {
    color: #666;
    font-size: 16px;
    line-height: 1.6;
    font-style: italic;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 1024px) {
    .hero-title {
      font-size: 32px;
    }
  
    .about-title {
      font-size: 28px;
    }
  
    .content-text {
      padding: 0 20px;
      font-size: 15px;
    }
  
    .section-heading {
      padding: 0 20px;
      font-size: 22px;
    }
  
    .content-footer {
      padding: 0 20px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .about-hero {
      height: 350px;
      margin-top: 60px;
    }
  
    .hero-title {
      font-size: 28px;
    }
  
    .about-title {
      font-size: 24px;
      margin-bottom: 30px;
    }
  
    .content-text {
      padding: 0 15px;
      font-size: 14px;
    }
  
    .section-heading {
      padding: 0 15px;
      font-size: 20px;
    }
  
    .content-footer {
      margin-top: 40px;
      padding: 0 15px;
    }
  
    .footer-text {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .about-hero {
      height: 300px;
    }
  
    .hero-title {
      font-size: 24px;
    }
  
    .about-title {
      font-size: 22px;
    }
  
    .content-text {
      padding: 0 10px;
      font-size: 13px;
    }
  
    .section-heading {
      padding: 0 10px;
      font-size: 18px;
      margin: 24px 0 12px;
    }
  
    .content-footer {
      margin-top: 30px;
      padding: 0 10px;
    }
  
    .footer-text {
      font-size: 13px;
    }
  }
  
  /* Animation for content appearance */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .content-section {
    animation: fadeIn 0.6s ease-out forwards;
  }